@tailwind base;
@tailwind components;
@tailwind utilities;

thead {
  @apply shadow-[0_0px_8px_0px_rgba(0,0,0,0.15)] rounded-[8px]
}

input[type="checkbox"]:checked {
  background-color: white;
  border-color: #0f182d;
}

.mantine-Checkbox-inner svg {
  color: #0f182d;
}

.mantine-1iq0i96::placeholder {
  color: #0f182d;
}

.mantine-18eradl {
  color: #0f182d;
  font-weight: 600;
  border-color: #0f182d;
}

.mantine-vvp47s {
  border-color: transparent;
}

.mantine-vvp47s:focus,
.mantine-vvp47s:focus-within {
  border-color: transparent;
}

.mantine-37ngpr9hv {
  justify-content: center;
}

.mantine-vvp47s::placeholder {
  color: #656565;
  font-weight: 600;
  padding-left: 20px;
}

/*DropZone Styles*/
.mantine-bx0zqe {
  border: 2px dashed #0f182d;
  border-radius: 24px;
  opacity: 1;
}

/*SearchInput Styles*/
.mantine-1w8ivjd {
  border: 1px solid #0f182d;
}
.mantine-1w8ivjd::placeholder {
  color: #737588;
}

/*Form dropdown - Single Scrub*/
.mantine-1u5idp6 {
  color: #0f182d;
}

.mantine-v4lv9f {
  border-bottom: transparent;
}

/*No funciona y no se va el icono*/
/*.mantine-v4lv9f:hover{
    background-color: red;
}*/

/*Box borderColor - Phone Scrub 2*/
.mantine-4716ii {
  border: 1px solid #d1d5db;
}

/*Below colorCards Btn - Phone Scrub 2*/
.mantine-gkqjuz {
  background-color: #0f182d;
}

/* Basic styles for nav links */
nav a {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 56px;
  text-decoration: none;
}

/* Specific styles for non-active links */
.link {
  color: #0f182d;
}

.gradient-button {
  background:  linear-gradient(to right,#3B3D40,#0F182D);
  border-radius: 5px;
}

.gradient-button:hover {
  background: #0F182D;
}

.link:hover { 
  background-color: #c0c3cc;
}

/* Specific styles for active links */
.link-active {
  color: white;
  background: #0f182d;
}

.link-active svg {
  filter: grayscale(1) invert(1);
}

/*Tooltip textColor - Phone Scrub 2*/
.mantine-Tooltip-body {
  color: #626262;
  font-size: 12px;
}

/*Pagination Button*/
.mantine-Pagination-active {
  background-color: #0f182d;
}

/*Button Back to list padding*/
.mantine-1cnnrxk {
  padding: 0px;
}

/*Text style - Input form profile*/
.mantine-1w07r5r {
  color: #0f182d;
  font-size: 16px;
}

.image-hover {
  filter: brightness(0) invert(1);
}

.text-webkit-center {
  text-align: -webkit-center;
}

.log-modal:nth-child(even) {
  background-color: rgba(128, 128, 128, 0.31);
}
